@import "styles/common";

.consideration {
  position: absolute;
  top: 0;
  left: 100%;
  display: inline;
  width: 136px;
  height: 20px;
  padding-bottom: 10px;
  padding-left: 5px;
  background: $application-status-updated;
  border-radius: 2px;

  &_main {
    position: absolute;
    top: 0;
    left: 100%;
    display: inline;
    width: 140px;
    height: 20px;
    padding-bottom: 10px;
    padding-left: 5px;
    font-size: 14px;
    text-align: center;
    background: $application-status-updated;
    border-radius: 2px;
  }

  &_description {
    color: $application-status-updated-text;
  }
}

.agreed {
  position: absolute;
  top: 0;
  left: 100%;
  display: inline;
  width: 100px;
  height: 20px;
  padding-bottom: 10px;
  padding-left: 5px;
  background: $application-status-success;
  border-radius: 2px;

  &_main {
    position: absolute;
    top: 0;
    left: 100%;
    display: inline;
    width: 100px;
    height: 20px;
    padding-bottom: 10px;
    padding-left: 5px;
    font-size: 14px;
    text-align: center;
    background: $application-status-success;
    border-radius: 2px;
  }

  &_description {
    color: $application-status-updated-text;
  }
}

.rejected {
  position: absolute;
  top: 0;
  left: 100%;
  display: inline;
  width: 83px;
  height: 20px;
  padding-bottom: 10px;
  padding-left: 5px;
  background: $application-status-failure;
  border-radius: 2px;

  &_main {
    position: absolute;
    top: 0;
    left: 100%;
    display: inline;
    width: 83px;
    height: 20px;
    padding-bottom: 10px;
    padding-left: 5px;
    font-size: 14px;
    text-align: center;
    background: $application-status-failure;
    border-radius: 2px;
  }

  &_description {
    color: $application-status-failure-text;
  }
}

@import 'styles/common';

.heading {
  margin-top: 3rem;
  color: $graphite-text;

  @include media-breakpoint-down(xs) {
    text-align: center;
  }
}

.head {
  display: inline-flex;
}

.steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 24px 0 56px;

  h3 {
    font-size: calc(var(--font-base) * 1.25);
    line-height: calc(var(--font-base) * 1.25);
    color: $graphite-text;

    @include media-breakpoint-down(sm) {
      font-size: calc(var(--font-base) * 1.125);
    }
  }

  p {
    margin: 0;
    font-size: calc(var(--font-base) * .875);
    line-height: calc(var(--font-base) * 1.375);
    color: $gray-text;
  }

  &__item {
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 384px;
    padding: 40px 0;
    text-align: center;
    background: $white;
    border-radius: 10px;
    opacity: .5;
    box-shadow: none;
    pointer-events: none;
    user-select: none;

    &:not(:last-child)::after {
      position: absolute;
      right: -28px;
      content: url("/images/symbols/right.svg");
      width: 24px;

      @include media-breakpoint-down(md) {
        right: auto;
        bottom: -28px;
        transform: rotate(90deg);
      }
    }

    &_active {
      opacity: 1;
      box-shadow: 0 0 60px rgb(60 67 80 / 12%);
      pointer-events: all;

      &:hover {
        bottom: 3px;
        cursor: pointer;
        box-shadow: 0 0 20px rgb(60 67 80 / 20%);
      }
    }

    &_accessible {
      border: 2px solid $content;
      box-shadow: none;
    }

    &_one {
      width: 250px;

      @include media-breakpoint-down(lg) {
        width: 207px;
      }

      @include media-breakpoint-down(md) {
        width: 380px;
      }

      @include media-breakpoint-down(xs) {
        width: 72%;
      }
    }

    &_two {
      width: 220px;

      @include media-breakpoint-down(lg) {
        width: 172px;
      }

      @include media-breakpoint-down(md) {
        width: 340px;
      }

      @include media-breakpoint-down(xs) {
        width: 66%;
      }
    }

    &_three {
      width: 260px;

      @include media-breakpoint-down(lg) {
        width: 207px;
      }

      @include media-breakpoint-down(md) {
        width: 390px;
      }

      @include media-breakpoint-down(xs) {
        width: 72%;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      margin: auto auto 16px;
      background: $graphite;
      border-radius: 100%;

      svg {
        fill: $white;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 12px;
      }
    }

    @include media-breakpoint-down(lg) {
      max-width: 288px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
      margin-bottom: 32px;
      padding: 32px 0;
    }

    @include transition($transition-base);
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin-bottom: 48px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 16px;
  }
}

.faq {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    margin-bottom: 80px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 48px;
  }

  &__block {
    border-bottom: 1px solid $gray;
  }

  &__left {
    padding-right: 16px;
    padding-left: 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__right {
    padding-right: 0;
    padding-left: 16px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    cursor: pointer;

    svg {
      width: var(--font-base);
      height: calc(var(--font-base) * 1.0625);
    }

    &__text {
      width: 93%;
      font-weight: normal;
      font-size: calc(var(--font-base) * 1.25);
      line-height: calc(var(--font-base) * 1.625);
      font-family: var(--font-family-heading);
      color: $graphite-text;

      @include media-breakpoint-down(sm) {
        font-size: calc(var(--font-base) * 1.125);
        line-height: calc(var(--font-base) * 1.375);
      }
    }

    &_up {
      margin-bottom: 16px;
      transform: rotate(270deg);
    }

    &_down {
      margin-bottom: 16px;
      transform: rotate(90deg);
    }
  }

  &__answer {
    position: relative;
    height: 0;
    font-size: calc(var(--font-base) * .875);
    line-height: calc(var(--font-base) * 1.4375);
    color: $gray-text;
    opacity: 0;
    transition: opacity 1s;
    overflow: hidden;

    p {
      margin: 0;
    }

    &_active {
      height: auto;
      padding-bottom: 16px;
      opacity: 1;
    }
  }
}

.contact {
  width: 100%;
  margin-bottom: 80px;
  padding: var(--padding-y) var(--padding-x);
  background: $white;
  border-radius: 10px;

  --padding-x: 48px;
  --padding-y: 40px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 60px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 128px;

    --padding-x: 40px;
    --padding-y: 32px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 80px;

    --padding-x: 24px;
    --padding-y: 32px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 48px;
  }

  &__paragraph {
    font-size: var(--font-base);
    color: $gray-text;

    @include media-breakpoint-down(sm) {
      font-size: calc(var(--font-base) * .875);
    }
  }

  &__button {
    margin-right: auto;
    font-size: calc(var(--font-base) * .875);
    font-family: var(--font-family-base);

    @include media-breakpoint-down(xs) {
      margin: auto;
    }
  }

  &__picture {
    position: absolute;
    bottom: calc(var(--padding-y) * -1);
    left: 50%;
    width: 488px;
    transform: translateX(-50%);

    &-wrapper {
      position: relative;
    }

    &_accessible {
      filter: grayscale(100%);
    }

    @include media-breakpoint-down(md) {
      right: 32px;
      width: 320px;
      height: 193px;
    }

    @include media-breakpoint-down(sm) {
      width: 200px;
      height: 121px;
    }

    @include media-breakpoint-down(xs) {
      position: static;
      bottom: unset;
      left: unset;
      width: 260px;
      height: 157px;
      transform: unset;
    }
  }
}

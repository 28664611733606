@import "styles/common";

.parentCards::-webkit-scrollbar {
  height: 5px;
}

.parentCards::-webkit-scrollbar-thumb {
  background-color: $hint;
  border-radius: 9em;
}

.parentCards {
  @include media-breakpoint-down(lg) {
    overflow-x: scroll;
  }
}

.svg {
  display: inline-block;
  flex: none;
  flex-grow: 0;
  width: 40px;
  height: 40px;
  margin: 0 14px;
  background: $white;
  border-radius: 4px;
  order: 0;

  svg {
    margin-top: 10px;
    margin-left: 11px;
    align-content: center;
  }
}

.card {
  position: relative;
  display: inline-block;
  width: 1216px;
  height: 146px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 24px;
  font-family: var(--font-family-heading);
  background: $white;
  border-radius: 8px;

  &_active {
    position: relative;
    display: inline-block;
    width: 1216px;
    height: 258px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 24px;
    font-family: var(--font-family-heading);
    background: $white;
    border-radius: 8px;
  }

  &_header {
    display: flex;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
  }

  &_bottom {
    position: absolute;
    max-width: 600px;
    margin-top: 2%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  &_name {
    display: flex;
  }

  &_number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 24px;
    margin-right: 16px;
    background: $primary;
    border-radius: 4px;

    span {
      font-size: 14px;
      color: white;
    }
  }

  &_title {
    width: 543px;
  }

  &_btn {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border: 1px solid $primary;
    border-radius: 4px;
  }

  &_accepted {
    position: absolute;
    top: 60px;
    left: 635px;
  }

  &_count {
    position: absolute;
    left: 760px;
  }

  &_watch {
    position: absolute;
    top: 60px;
    left: 800px;
    color: $link;
  }

  &_status {
    position: absolute;
    top: 58px;
    left: 940px;
    width: 116px;
    height: 24px;
    padding-top: 3px;
    text-align: center;
    border-radius: 2px;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  height: 88px;
  margin-top: 80px;
  background: $modal-background;
  border-radius: 8px;

  @include media-breakpoint-down(md) {
    width: 100%;
    height: 88px;
  }

  &_elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 528px;
    height: 40px;
    padding-top: 4%;
    gap: 5px;

    @include media-breakpoint-down(md) {
      margin-left: 40px;
      font-size: 12px;
    }

    u {
      display: inline-block;
      margin-bottom: 5px;
      font-size: 14px;
      text-decoration: none;
    }
  }

  &_labels {
    padding-right: 10px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}

.heading {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.info {
  display: flex;
  width: 1200px;
  height: 40px;
  font-size: 14px;
  color: $gray-placeholder;

  &_program {
    height: 100px;
    margin-right: 33%;
    padding-left: 10px;
  }

  &_statement {
    width: 10%;
  }

  &_amount {
    width: 4%;
    margin-left: 2%;
  }

  &_placeRanking {
    width: 6%;
    margin-left: 5%;
  }

  &_status {
    width: 76px;
    margin-left: 8%;
  }
}

.btn__prev {
  min-height: 40px;
  margin-top: 5px;
  font-size: 12px;

  @media screen and (max-width: 500px) {
    max-width: 200px;
    margin-bottom: 16px;
  }
}
